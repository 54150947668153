import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import { FaChevronDown } from 'react-icons/fa';

const Hero = () => {
  const [showFinalText, setShowFinalText] = useState(false);

  const features = [
    '東大大学院合格者が直接指導！',
    700,
    '歴史が証明する脅威の暗記術',
    700,
    '一人ひとりに合った学習ペース',
    700,
    '24時間オンライン学習',
    700,
    '高品質な少人数集団授業',
    700,
    'すべてがここに！',
    1000,
    () => setShowFinalText(true)
  ];
  
  const scrollToNextSection = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  const containerVariants = {
    hidden: { 
      opacity: 0,
      scale: 0.8,
      z: -200,
    },
    visible: {
      opacity: 1,
      scale: 1,
      z: 0,
      transition: {
        type: "spring",
        damping: 15,
        stiffness: 70,
        duration: 0.8
      }
    }
  };

  const linkStyle = {
    color: '#1a73e8',
    textDecoration: 'none',
  };

  return (
    <section className="relative h-screen overflow-hidden bg-gradient-to-b from-white to-gray-100" id="top">
      <div className="relative z-10 container mx-auto px-6 h-full flex flex-col justify-center items-center text-primary perspective-1000">
        <AnimatePresence mode="wait">
          {!showFinalText ? (
            <motion.div
              key="typing"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-2xl md:text-4xl font-bold mb-8 text-center h-20"
            >
              <TypeAnimation
                sequence={features}
                wrapper="span"
                cursor={true}
                repeat={0}
                speed={45}
                deletionSpeed={70}
                omitDeletionAnimation={false}
                style={{ display: 'inline-block' }}
              />
            </motion.div>
          ) : (
            <motion.div
              key="final"
              className="text-center flex flex-col items-center"
            >
              <motion.img
                src="/images/logo.png"
                alt="ぐっとスクールロゴ"
                className="mb-6 w-full max-w-4xl h-auto"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.8 }}
              />
              <TypeAnimation
                sequence={[
                  '最新と伝統の融合',
                  2000,
                ]}
                wrapper="h1"
                cursor={true}
                repeat={0}
                speed={40}
                className="text-3xl md:text-5xl font-bold mb-4 text-primary"
              />
              <motion.p
                className="text-lg md:text-xl mb-6 text-gray-700"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1, delay: 0.7}}
              >
                未来をつかむ学びがここに！
              </motion.p>
              <motion.a
                href="#お問い合わせ"
                className="bg-blue-600 text-white px-6 py-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
                whileHover={{ scale: 1.05 }}
              >
                今すぐ無料体験を申し込む
              </motion.a>
            </motion.div>
          )}
        </AnimatePresence>

        {showFinalText && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="absolute bottom-10 left-1/2 transform -translate-x-1/2 cursor-pointer z-20"
            onClick={scrollToNextSection}
          >
            <motion.div
              animate={{ y: [0, 10, 0] }}
              transition={{ repeat: Infinity, duration: 1.5 }}
            >
              <FaChevronDown size={30} color="#333" />
            </motion.div>
          </motion.div>
        )}
      </div>
    </section>
  );
};

export default Hero;