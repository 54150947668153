import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import ReactDOM from 'react-dom';

const instructors = [
  {
    name: "石本 大貴",
    subject: "講師　担当：全教科",
    image: "images/ishimoto_h.jpg",
    shortDescription: "東京大学大学院合格。一人ひとりへの手厚いフォローで、あなたの可能性を最大限に引き出します。",
    description: (
      <>
        <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-3">石本 大貴</h3>
        <p className="text-gray-600 dark:text-gray-300 mb-4">東京大学大学院合格。逆転合格のスペシャリストとして、あなたの可能性を最大限に引き出します。</p>
        <h4 className="text-xl font-semibold text-gray-800 dark:text-gray-200 mb-2">メッセージ:</h4>
        <blockquote className="italic text-gray-600 dark:text-gray-400 border-l-4 border-blue-500 pl-4 mb-4">
          「勉強はスポーツに似ています。適切な方法と努力で、誰でも成長できます。一緒に可能性を広げましょう！」
        </blockquote>
      </>
    ),
    achievements: [
      "愛知県立西高等学校 卒業",
      "名古屋大学工学部 機械･航空工学科 卒業",
      "東京大学大学院 機械工学専攻 中退",
      "塾講師・家庭教師として9年の指導経験",
      "アクティブブレインセミナー 最年少認定講師 記憶マイスター",
      "記憶工学研究所 最年少研究員",
      "2022年 保育士試験 合格",
      "2024年 MENSA合格"
    ],
    motto: [
      "個々の可能性を信じ、諦めない",
      "効果的な学習法を実践的に伝授",
      "まずは手厚くフォロー。目指すは自主的な学習",
      "温故知新"
    ]
  },
  {
    name: "石本 裕未",
    subject: "動画編集・デザイン・事務等",
    image: "/api/placeholder/200/200",
    shortDescription: "学生時代の経験を活かし、わからない子にも寄り添いながら、親身になってサポートします！",
    description: "学生時代、勉強は苦手でした。その経験を活かし、わからない子にも寄り添いながら、親身になってサポートします！",
    message: "勉強が苦手だった経験があるからこそ、皆さんの気持ちがよくわかります。一緒に、楽しみながら成長していきましょう！",
    achievements: [
      "動画編集のプロフェッショナル",
      "使いやすいデザインで生徒たちをサポート",
      "効率的な事務管理でスクールの運営をバックアップ",
      "現在出産・子育てに専念中"
    ]
  }
];

const Modal = ({ isOpen, onClose, instructor }) => {
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4"
        onClick={onClose}
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          transition={{ type: "spring", damping: 15 }}
          className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">{instructor.name}</h2>
              <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                <X size={24} />
              </button>
            </div>
            {instructor.description}
            <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">主な経歴・資格等：</h3>
            <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 mb-4">
              {instructor.achievements.map((achievement, i) => (
                <li key={i}>{achievement}</li>
              ))}
            </ul>
            {instructor.motto && (
              <>
                <h3 className="text-xl font-semibold mb-2 text-gray-900 dark:text-white">指導モットー：</h3>
                <ul className="list-disc list-inside text-gray-600 dark:text-gray-400">
                  {instructor.motto.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>,
    document.body
  );
};
const TeacherProfile = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      }
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    const currentRef = componentRef.current;

    return () => {
      if (currentRef) {
      observer.unobserve(currentRef);
      }
    };
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        staggerChildren: 0.2,
        delayChildren: 0.1,
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        type: "spring",
        stiffness: 100,
        damping: 12
      }
    }
  };

  const openModal = (instructor) => {
    setSelectedInstructor(instructor);
  };

  const closeModal = () => {
    setSelectedInstructor(null);
  };

  return (
    <section id="講師紹介" ref={componentRef} className="py-20 bg-gray-100">
      <div className="container mx-auto px-6">
        <motion.h2 
          initial={{ opacity: 0, y: 20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          className="text-4xl font-bold text-center mb-12"
        >
          スタッフ紹介
        </motion.h2>
        <AnimatePresence>
          <motion.div
            variants={containerVariants}
            initial="hidden"
            animate={isVisible ? "visible" : "hidden"}
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
          >
            {instructors.map((instructor) => (
              <motion.div
                key={instructor.name}
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.03,
                  boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
                  transition: { duration: 0.3 }
                }}
                className="bg-white dark:bg-gray-800 rounded-lg shadow-lg overflow-hidden cursor-pointer"
                onClick={() => openModal(instructor)}
              >
                <div className="flex flex-col md:flex-row">
                  <div className="w-full md:w-1/3">
                    <img 
                      className="w-full h-64 md:h-full object-cover object-center" 
                      src={instructor.image} 
                      alt={instructor.name} 
                    />
                  </div>
                  <div className="w-full md:w-2/3 p-6">
                    <h3 className="text-2xl font-semibold text-gray-900 dark:text-white mb-2">{instructor.name}</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-300 mb-4">{instructor.subject}</p>
                    <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-3">
                      {instructor.shortDescription}
                    </p>
                    <span className="text-blue-600 hover:text-blue-800 transition duration-300">
                      詳細を見る
                    </span>
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
      <Modal isOpen={!!selectedInstructor} onClose={closeModal} instructor={selectedInstructor} />
    </section>
  );
};

export default TeacherProfile;